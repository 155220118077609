@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');

@mixin font_QuickSand() {
    font-family: 'Quicksand', sans-serif !important;
    line-height: 1.5;
}

.fontQuickSand{
    font-family: 'Quicksand', sans-serif !important;
    line-height: 1.5;
}