@import "./mixins";
@import "./color";
@import "./font_Quicksand";

.about {
  .content {
  @include font_QuickSand();
    margin: 1% 0 !important;
    text-align: justify;
    text-indent: 0;
  }

  .newGen {
    position: relative;

    .newGenBlock {
      background-size: cover;
    }
  }

  .customCard {
    box-shadow: 1px 3px 7px -2px rgba(0, 0, 0, 0.68);
    margin: 2% auto;
    padding: 2%;
  }

  .card {
    margin: 2% auto;
    padding: 2%;
    // border: .25px solid #000;
  }

  .card:nth-child(odd) {
    background-color: $color-OffWhite;
  }

  li {
    list-style-type: none;
    display: inline-block;
  }
}