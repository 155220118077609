@import './color';
@import './mixins';
.home {
    display: block;
    @include font-size(20px);
    // @include font_Poppins();
    .video-wrapper {
        /* Telling our absolute positioned video to 
        be relative to this element */
        position: relative;
        width: 100%;
        height: 75vh;
        /* Will not allow the video to overflow the 
        container */
        overflow: hidden;
        /* Centering the container's content vertically 
        and horizontally */
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .viewport-header {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    video {
        position: absolute;
        width: 100vw;
        top: 0;
        left: 0;
        background: $color-OffWhite;
        object-fit: cover;
    }
    .firstSection {
        color: $color-OffWhite;
        position: absolute;
        text-align: center;
        .firstSection_text {
            h1 {
                font-weight: bolder;
                &,
                p {
                    line-height: 10rem;
                }
            }
        }
        .firstSection_Box {
            margin: 1% 0;
            padding: 1%;
            button {
                background-color: $color-Blue;
                padding: 1%;
                &:hover,
                &:focus {
                    background-color: $color-white;
                    color: $color-Blue;
                }
            }
        }
    }
    .minContact {
        background-color: $color-white;
        height: 10vh;
        margin: 0 5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .transparentBg {
        background: none !important;
    }
}
@media (max-width: 850px) {
    .home {
    .video-wrapper {
        height: 70vh;
    }
    video {
        height: 70vh;
    }
    .minContact {
        display: block;
        height: auto;
        text-align: center;
    }
    .firstSection {
        .firstSection_text {
            h1 {
                font-weight: bolder;
                &,
                p {
                    line-height: 6rem;
                }
            }
            }
        }
    }
}