@import "./color";
@import "./mixins";
@import "./font_Quicksand";

.contact {
  .content {
    @include font_QuickSand();
      margin: 1% 0 !important;
      text-align: justify;
      text-indent: 0;
    }
  .customForm {
    background-color: $color-white;
    padding: 3%;
    // margin: 1% auto;
  }
  a {
    text-decoration: none;
    color: #000;
    &:hover {
      color: $color-Blue;
    }
  }
  .iconContent {
    display: flex;
    align-items: center;
    line-height: 2.5;
  }
  .icon {
    color: $color-Blue;
  }
  .customCard {
    box-shadow: 1px 3px 7px -2px rgba(0, 0, 0, 0.68);
    margin: 2% auto;
    padding: 2%;
    // background-size: ;
  }
}
