@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');
@mixin font_Georgia() {
    font-family: 'Kalam', cursive !important;
}

.fontGeorgia {
    font-family: 'Kalam', cursive !important;
}

.fontDancing {
    font-family: 'Kalam', cursive !important;
}