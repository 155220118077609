@import "./color";
@import "./mixins";
@import "./font_Georgia";
@import "./font_Quicksand";
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
}

.addressFooter {
  background-color: rgba(67, 75, 77, 0.85);
  padding: 2%;
  color: $color-white;
  a {
    text-decoration: none;
    color: $color-white;
    &:hover {
      color: $color-OffWhite;
    }
  }
}
footer {
  flex-shrink: 0;
  background-color: $color-dark;
  color: $color-white;
  padding: 2%;
  .spanText {
    color: $color-OffWhite;
    @include font_Georgia();
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  @media (max-width: 850px) {
    .spanText {
      display: block;
    }
  }
  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      color: $color-Blue;
    }
  }
  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center
  }
  .iconContent {
    display: flex;
    align-items: center;
    line-height: 2.5;
  }
}

body {
  @include font-size(16px);
  background-color: $color-white;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #fff;
  &:hover {
    color: $color-Blue;
  }
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center
}
.iconContent {
  display: flex;
  align-items: center;
  line-height: 2.5;
}

.glassCard {
  backdrop-filter: blur(0px) saturate(109%);
    -webkit-backdrop-filter: blur(0px) saturate(109%);
    background-color: rgba(255, 255, 255, 0.32);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.8);
}
.firstSection {
  p {
    font-size: 20px;
  }
  padding: 5%;
}

section {
  padding: 1%;
  min-height: 80vh;
}

.WhiteBg {
  background-color: $color-OffWhite !important;
}

.greySection {
  background-color: $color-OffWhite !important;
}

.sectionTitle {
  color: $color-Blue;
  text-align: center;
  padding: 1%;
  font-weight: 700 !important;
  font-size: 1.75em !important;
  text-transform: uppercase;
  margin: .5em 0 !important
}

.welcomePage {
  .welcomePage_Contents {
    align-self: center;
    align-items: center;
    .welcomeSpeech {
      text-align: justify;
    }
  }
}

.servicePage {
  .service {
    h2 {
      font-size: 1.75vw !important;
      text-align: center;
      color: $color-Blue;
    }
  }
}

.top {
  margin-top: 6em;
}
@media (max-width: 850px) {
  .top {
    margin-top: 3em;
  }
}
.text-center {
  text-align: center !important;
  margin: 3% auto;
}
.align-stretch {
  align-items: stretch;
}
img {
  width: 100%;
}

.ministryCard {
  img {
    width: 100%; 
    height: 20rem;
    margin: 0 auto;
  }
  text-align: center;
//   margin: 5% auto;
}
