
.events {
  text-align: center !important;
  max-width: 430px;
  flex-grow: 1;
  margin: 2%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
   .content {
      margin: 2% 0 3% !important;
      font-weight: 400;
      // text-align: justify;
      font-size: 1em !important;
    }
}