@function calculate-rem($size) {
    $rem-size: $size / 16px;
    @return #{$rem-size}rem;
}

@mixin font-size($size) {
    font-size: calculate-rem($size) !important;
}

@mixin wobble-top {
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    transform-origin: 0 100%;
    &:active,
    &:focus,
    & :hover {
        animation-name: hvr-wobble-top;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
    }
}

@keyframes hvr-wobble-top {
    16.65% {
        -webkit-transform: skew(-12deg);
        transform: skew(-12deg);
    }
    33.3% {
        -webkit-transform: skew(10deg);
        transform: skew(10deg);
    }
    49.95% {
        -webkit-transform: skew(-6deg);
        transform: skew(-6deg);
    }
    66.6% {
        -webkit-transform: skew(4deg);
        transform: skew(4deg);
    }
    83.25% {
        -webkit-transform: skew(-2deg);
        transform: skew(-2deg);
    }
    100% {
        -webkit-transform: skew(0);
        transform: skew(0);
    }
}