:root {
  --gradient: linear-gradient(
    90deg,
    transparent,
    transparent 40%,
    #14ffe9,
    #ffeb3b,
    #ff00e0
  );
  --loader-gradient: linear-gradient(270deg, #101010, #303030);
}

.loader-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--loader-gradient);
  background-size: 400% 400%;

  -webkit-animation: rotate-bg 3s ease infinite;
  -moz-animation: rotate-bg 3s ease infinite;
  animation: rotate-bg 3s ease infinite;

  display: flex;
  align-items: center;
  justify-content: center;
}
.preData{
  display: inline;
  color: white;
  z-index: 1;
}

/* CARREGAMENTO */
.loader {
  position: absolute;
  width: 200px;
  height: 200px;
  transition: 0.5s;
}

.circle-loader {
  animation: rotate-circle 1s linear infinite;
  border-radius: 50%;
}

.box-loader {
  animation: rotate-box 3s ease infinite;
}

.loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--gradient);
  background-size: 200% 200%;
  transition: 0.5s;

  -webkit-animation: rotate-gradient 3s ease infinite;
  -moz-animation: rotate-gradient 3s ease infinite;
  animation: rotate-gradient 3s ease infinite;
}

.circle-loader span {
  border-radius: 50%;
}

.loader:after {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;

  background: var(--loader-gradient);
  background-size: 400% 400%;

  -webkit-animation: rotate-bg 3s ease infinite;
  -moz-animation: rotate-bg 3s ease infinite;
  animation: rotate-bg 3s ease infinite;

  transition: 0.5s;
}

.circle-loader:after {
  border-radius: 50%;
}

.loader span:nth-child(1) {
  filter: blur(5px);
}
.loader span:nth-child(2) {
  filter: blur(10px);
}
.loader span:nth-child(3) {
  filter: blur(25px);
}
.loader span:nth-child(4) {
  filter: blur(50px);
}

.pausado {
  width: 150px;
  height: 150px;
}
.pausado:after {
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
}
.pausado span:nth-child(1),
.pausado span:nth-child(2),
.pausado span:nth-child(3),
.pausado span:nth-child(4) {
  filter: blur(0px);
}

.sumir {
  animation: vanish 0.5s forwards;
}

@keyframes vanish {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(220deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-box {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  35% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  85% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes rotate-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes rotate-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes rotate-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
@-moz-keyframes rotate-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
@keyframes rotate-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
